@import '../../globals/variables.scss';
.howItWorks {
    background-color: #eee5dc;
    padding: 50px 0;
    .howItWorksInner {
        h3 {
            margin: 0 0 50px;
            font-size: 38px;
            font-weight: 600;

            text-align: center;
        }
        .steps {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            div {
                width: 30%;
                text-align: center;
                img {
                    max-width: 100%;
                    border-radius: 15px;
                }
                p {
                    font-weight: 600;
                    width: 65%;
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 1.7;
                    background-color: #000;
                    display: inline-block;
                    text-align: center;
                    padding: 15px;
                    border-radius: 10px;
                    margin: 0 auto;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
