@import '../../globals/variables.scss';
@media screen and (max-width: 992px) {
    .examples {
        .examplesInner {
            h3 {
                font-size: 38px;
                text-align: center;
            }
            .exampleImages {
                flex-direction: column;
                div {
                    width: 100%;
                    text-align: center;
                    p {
                        width: 50%;
                    }
                    img {
                        max-height: 360px;
                        width: 75%;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}
