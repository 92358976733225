@import '../../../globals/variables.scss';

.footer {
    border-top: 5px solid #bcbcbc;
    background-color: whitesmoke;
    padding: 0;
    color: white;
    img {
        width: 100px;
        transform: scale(1.7) translate(10%, 10%);
    }

    .innerFooter {
        padding: 20px;
        width: 100%;
        max-width: $wrapperMaxWidth;
        margin: 0 auto;
        .disclaimer {
            font-size: 14px;
            max-width: 700px;
            line-height: 1.7;
            color: black;
        }
        p {
            font-weight: 400;
            letter-spacing: 0.5px;
            font-size: 13px;
            color: black;
            a {
                color: black;
                display: inline-block;
                margin-right: 20px;
            }
        }
    }
    .footerBottom {
        padding: 15px 20px;
        background-color: #9b9262;
        p {
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-weight: 100;
            font-size: 12px;
            margin: 0;
            letter-spacing: 1.1px;
        }
    }
}
