@import '../../globals/variables.scss';
.twoCols {
    .twoColsInner {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px;
        margin: 50px 0;
        div {
            width: 50%;
            &.colContent {
                padding: 20px;
                h3 {
                    font-size: 38px;
                }
                p {
                    font-size: 16px;
                    color: #000;
                    line-height: 1.7;
                }
            }

            &.colForm {
                padding: 20px;
                display: flex;
                align-items: center;

                > div {
                    padding: 50px;
                    min-width: 75%;
                    background-color: black;
                    border-radius: 8px;
                    color: white;
                    text-align: center;
                    p {
                        font-size: 30px;
                        font-weight: 600;
                        margin: 0;
                        color: white;
                        width: 100%;
                        display: block;
                    }
                    form {
                        input {
                            margin: 10px auto 0;
                            padding: 23px;
                            display: block;
                            width: 80%;
                            box-sizing: border-box;
                            border-radius: 10px;
                            outline: none;
                            color: lightgrey;
                            font-size: 20px;
                        }
                        button {
                            margin: 10px auto 0;
                            padding: 23px;
                            display: block;
                            width: 80%;
                            box-sizing: border-box;
                            border-radius: 10px;
                            background-color: $primaryCTA;
                            font-size: 23px;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                margin-left: 10px;
                                max-width: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
