@import '../../globals/variables.scss';
.mainForm {
    .mainFormContent {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
        position: relative;
        background-image: url('../../assets/hero.jpg');
        background-position: center;
        background-size: cover;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #726a2b;
            opacity: 0.9;
        }
        .formContainer {
            position: relative;
            max-width: 800px;
            background-color: white;
            padding: 40px 40px 40px 40px;
            border-radius: 10px 10px 10px 10px;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
            h1 {
                margin-top: 0;
                font-size: 26px;
                text-align: center;
            }
            p {
                text-align: center;
                font-size: 16px;
            }
            h2 {
                max-width: 70%;
                text-align: center;
                margin: 40px auto;
            }
            fieldset {
                border: none;
                padding: 0;
                label {
                    font-size: 18px;
                    display: block;
                    margin: 10px 0;
                }
                select {
                    font-size: 17px;
                    outline: none;
                    color: grey;
                    display: block;
                    width: 100%;
                    padding: 20px;
                    border-radius: 8px;
                    background-color: #f8f8f8;
                    border: 1px solid #dbdbdb;
                    box-sizing: border-box;
                }
            }
            input {
                font-size: 17px;
                outline: none;
                color: grey;
                display: block;
                width: 100%;
                padding: 21px;
                border-radius: 8px;
                background-color: #f8f8f8;
                border: 1px solid #dbdbdb;
                box-sizing: border-box;
                margin-bottom: 20px;
            }
            button {
                width: 100%;
                padding: 20px 0;
            }
        }
    }
}
