@import '../../globals/variables.scss';
@media screen and (max-width: 992px) {
    .twoCols {
        .twoColsInner {
            margin-top: 0;
            display: flex;
            flex-direction: column;
            padding: 0;
            > div {
                max-width: 100%;
                width: 100%;
                padding: 0;

                &.colContent {
                    padding: 0;
                    width: 100%;
                }
                &.colForm {
                    padding: 0;
                    display: flex;
                    align-items: center;

                    > div {
                        padding: 50px;
                        min-width: 100%;
                        box-sizing: border-box;
                        background-color: black;
                        border-radius: 8px;
                        color: white;
                        text-align: center;
                        p {
                            font-size: 30px;
                            font-weight: 600;
                            margin: 0;
                            color: white;
                            width: 100%;
                            display: block;
                        }
                        form {
                            input {
                                margin: 10px auto 0;
                                padding: 23px;
                                display: block;
                                width: 80%;
                                box-sizing: border-box;
                                border-radius: 10px;
                                outline: none;
                                color: lightgrey;
                                font-size: 20px;
                            }
                            button {
                                margin: 10px auto 0;
                                padding: 23px;
                                display: block;
                                width: 80%;
                                box-sizing: border-box;
                                border-radius: 10px;
                                background-color: $primaryCTA;
                                font-size: 23px;
                                font-weight: 600;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                img {
                                    margin-left: 10px;
                                    max-width: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
