@import '../../globals/variables.scss';
.twoCols {
    .twoColsInner {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px;
        margin: 50px 0;
        &.flip {
            flex-direction: row-reverse;
        }
        img {
            width: 100%;
            border-radius: 7px;
        }
        > div {
            max-width: 50%;
            padding: 20px;
            h3 {
                margin: 0;
                font-size: 38px;
            }
            p {
                font-size: 16px;
                color: #000;
                line-height: 1.7;
            }
        }
    }
}
