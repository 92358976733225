@import '../../globals/variables.scss';
@media screen and (max-width: 992px) {
    .hero {
        .heroContent {
            h1 {
                font-size: 34px;
            }
        }
    }
}
@media screen and (max-width: 640px) {
    .hero {
        .heroContent {
            .heroForm {
                fieldset {
                    display: flex;
                    flex-direction: column;
                    input {
                        padding: 25px;
                        border-radius: 8px;
                        border: none;
                        font-size: 20px;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                        width: 100%;
                        outline: none;
                        color: grey;
                        box-sizing: border-box;
                        margin-bottom: 15px;
                    }
                    button {
                        width: 100%;
                        padding: 25px;
                        border-radius: 8px;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                        text-align: center;
                        justify-content: center;
                    }
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }
}
