@import '../../../globals/variables.scss';

.inputStyle {
    font-size: 17px;
    outline: none;
    color: grey;
    display: block;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: #f8f8f8;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    margin-bottom: 20px;

    @media screen and (max-width: 992px) {
        width: 100%;
        display: block;
        max-width: 100%;
        box-sizing: border-box;
    }
}

.inputStyle.errorBorder {
    border: 1px solid red !important;
}

span.notice {
    display: block;
    color: red;
    font-size: 11px;
    transform: translateY(-20px);
}
.uppercase {
    text-transform: uppercase;
    letter-spacing: 1.3px;
    text-align: center;
    font-weight: 800;
}
