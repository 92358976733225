@import './variables.scss';

.pageContent {
    padding: 0;
    margin: 0;
    p,
    ul li,
    a {
        font-size: 17px;
        font-family: $font;
        @media screen and (max-width: 992px) {
            font-size: 15px;
        }
    }
}
p {
    color: black;
}
.pcpClaimFormContainer {
    @media screen and (max-width: 992px) {
    }
}

.pageBody {
    text-align: left;
    padding: 10px 0 30px;
    h2 {
        font-size: 25px;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 15px;
    }
    p,
    li {
        color: #333;
    }
    li {
        font-weight: 600;
        line-height: 2;
    }
}

.previews {
    display: flex;
    padding-bottom: 70px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    embed {
        border: 1px solid grey;
        border-radius: 9px;
        overflow: hidden;
    }
    a {
        color: grey;
        font-weight: 400;
        text-decoration: none;
        font-style: italic;
        letter-spacing: 1px;
    }
}

.sliderContainer {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 60px;
    > p {
        margin: 15px auto 0;
        text-align: center;
        font-size: 25px;
        font-weight: 600;
    }
    > div:first-of-type {
        margin-top: 20px !important;
        > div:first-of-type {
            background: $primary;
        }
    }
    li {
        color: black;
    }
    .sliderLabels {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        label {
            margin: 0;
        }
    }
}

.desktopStyle {
    display: block !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.desktopStyleFlex {
    display: flex !important;

    @media screen and (max-width: 768px) {
        display: none !important;
    }
}

.mobileStyle {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: block !important;
    }
}

.mobileStyleFlex {
    display: none !important;

    @media screen and (max-width: 768px) {
        display: flex !important;
    }
}
