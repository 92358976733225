@import '../../globals/variables.scss';
@media screen and (max-width: 992px) {
    .mainForm {
        .mainFormContent {
            padding: 0;
            .formContainer {
                border-radius: 0;
                box-shadow: none;
                max-width: none;
                width: 100%;

                h2 {
                    max-width: 90%;
                    text-align: center;
                    margin: 40px auto;
                    font-size: 19px;
                }
            }
        }
    }
}
