@import '../../globals/variables.scss';
@media screen and (max-width: 992px) {
    .twoCols {
        .twoColsInner {
            flex-direction: column;
            padding: 0;

            &.flip {
                flex-direction: column-reverse;
            }
            > div {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
