@import '../../../globals/variables.scss';

.header {
    position: relative;
    height: auto;
    background-color: white;
    z-index: 1;
    img {
        width: 180px;
        transform: scale(1.7) translate(10%, 10%);
        // max-width: 100%;
        @media screen and (max-width: 992px) {
            max-width: 220px;
        }
    }
    .borderBottomBar {
        background-color: #000000;
        height: 10px;
        width: 100%;
        display: block;
        position: relative;
        &:before {
            content: '';
            width: 33%;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            background-color: #9a8013;
        }
    }
}

.innerHeader {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 0px;

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0;
        a {
            display: flex;
        }
        img {
            margin-right: 40px;
            width: 150px;
            object-fit: contain;
        }
    }
}
