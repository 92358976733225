@import '../../../globals/variables.scss';

.cta {
    padding: 12px 15px;
    border: none;
    cursor: pointer;
    font-size: 19px;
    font-weight: 600;
    border-radius: 6px;
    position: relative;
    text-align: center;
    @media screen and (max-width: 992px) {
        font-size: 16px;
        padding: 12px 8px;
    }
}
.primary {
    font-weight: 600;
    color: black;
    background-color: $primaryCTA;
    border: 1px solid $primaryCTA;
}
.secondary {
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #000000;
    animation: pulse 2s infinite;
}
.orangeNoAnim {
    color: #ffffff;
    background-color: #eba315;
    border: 1px solid #eba315;
}
.grey {
    color: whitesmoke;
    background-color: #e3e3e3;
    border: 1px solid #e3e3e3;
}

.primaryAnimated {
    color: white;
    background-color: $primary;
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.9);
    }

    60% {
        transform: scale(1);
        box-shadow: 0 0 0 50px rgba($primary, 0);
    }

    100% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba($primary, 0);
    }
}
