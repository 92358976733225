@import '../../globals/variables.scss';
.threePointDiv {
    .threePointDivInner {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: #eee5dc;
        border-radius: 15px;
        padding: 20px;
        margin: 50px 0;
        div {
            display: flex;
            align-items: center;
            width: 30%;
            flex-shrink: 0;
            img {
                max-width: 50px;
                color: #8c7539;
                margin-right: 10px;
            }
            p {
                font-size: 22px;
                color: #070226;
                font-weight: 600;
            }
        }
    }
}
