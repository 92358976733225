@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400..700;1,400..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

$primary: #001a0a;
$primaryGreen: #043c04;
$primaryCTA: #f8e71c;

$darkgreenGradient: linear-gradient(90.08deg, #d5b891 1.32%, #a4713f 100.87%);
$wrapperMaxWidth: 1200px;
$timing: 1500ms;
$bigtiming: 1750ms;
$inputWidth: 100%;
$inputWidthMax: 900px;
$font: 'Instrument Sans', sans-serif;
