@import '../../globals/variables.scss';
.hero {
    background-image: url('../../assets/hero.jpg');
    background-size: cover;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #726a2b;
        opacity: 0.9;
        z-index: 0;
    }

    .heroContent {
        position: relative;
        text-align: center;
        padding: 80px 0;
        color: white;
        h1 {
            color: white;
            font-family: 'Instrument Sans', Sans-serif;
            font-size: 46px;
            line-height: 1.2em;
            max-width: 760px;
            margin: 0 auto;
            text-align: center;
            font-weight: 500;
        }
        p {
            color: white;
            line-height: 1.6;
            font-size: 18px;
            margin-bottom: 25px;
            span {
                font-weight: 800;
            }
        }
        .heroForm {
            margin-top: 20px;
            fieldset {
                border: none;
                display: flex;
                margin: 0 auto;
                position: relative;
                justify-content: center;
                input {
                    padding: 25px;
                    border-radius: 8px;
                    border: none;
                    font-size: 20px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    width: 300px;
                    outline: none;
                    color: grey;
                }
                button {
                    font-weight: 700;
                    padding: 15px 75px;
                    border-radius: 8px;
                    border: none;
                    font-size: 20px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    background-color: $primaryCTA;
                    display: flex;
                    align-items: center;
                    color: black;
                    img {
                        margin-left: 5px;
                        max-width: 12px;
                    }
                }
            }
            p {
                font-size: 12px;
            }
        }
    }
}
