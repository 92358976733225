@import '../../globals/variables.scss';
@media screen and (max-width: 992px) {
    .threePointDiv {
        .threePointDivInner {
            flex-direction: column;
            > div {
                flex-direction: column;
                text-align: center;
                width: 100%;
                margin-top: 20px;
            }
        }
    }
}
